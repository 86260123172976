import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../styles/footer.css";
import "../styles/arrows.css";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <section>
        <div>
          <img
            className="navLogo"
            src={t("footer.logopath")}
            alt="Schwachmeyer Gottschalk Logo"
          />
        </div>
        <div className="footerColumn-Container">
          <div className="footerColumn" style={{ alignItems: "flex-start" }}>
            <p style={{ marginLeft: "10px" }}>{t("footer.location")}</p>
            <Link className="link" to="/kontakt" style={{ marginLeft: "0px" }}>
              post@schwachmeyer-gottschalk.de
            </Link>
          </div>

          <menu className="footerColumn" style={{ alignItems: "flex-start" }}>
            <ul>
              <li>
                <Link className="animated-arrow dark-theme" to="/">
                  <span className="the-arrow -left">
                    <span className="shaft"></span>
                  </span>
                  <span className="main">
                    <span className="text">{t("navigation.home")}</span>
                    <span className="the-arrow -right">
                      <span className="shaft"></span>
                    </span>
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  className="animated-arrow dark-theme"
                  to="/barrierefreiheit"
                >
                  <span className="the-arrow -left">
                    <span className="shaft"></span>
                  </span>
                  <span className="main">
                    <span className="text">{t("navigation.acc")}</span>
                    <span className="the-arrow -right">
                      <span className="shaft"></span>
                    </span>
                  </span>
                </Link>
              </li>
              {/* <li>
                <Link
                  className="animated-arrow dark-theme"
                  to="/nachhaltigkeit"
                >
                  <span className="the-arrow -left">
                    <span className="shaft"></span>
                  </span>
                  <span className="main">
                    <span className="text">{t("navigation.sus")}</span>
                    <span className="the-arrow -right">
                      <span className="shaft"></span>
                    </span>
                  </span>
                </Link>
              </li> */}
              <li>
                <Link className="animated-arrow dark-theme" to="/kontakt">
                  <span className="the-arrow -left">
                    <span className="shaft"></span>
                  </span>
                  <span className="main">
                    <span className="text">{t("navigation.cont")}</span>
                    <span className="the-arrow -right">
                      <span className="shaft"></span>
                    </span>
                  </span>
                </Link>
              </li>
            </ul>
          </menu>

          {/* <div className="footerColumn" style={{ alignItems: "flex-end" }}>
            <ul>
              <li>
                <Link to="/" aria-label={t("links.insta")}>
                  <svg
                    id="Ebene_1"
                    data-name="Ebene 1"
                    className="iconFooter"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 47.97 47.96"
                    width={32}
                  >
                    <path
                      className="cls-1"
                      d="m47.93,24.09c0,3.2.06,6.41-.02,9.61-.09,3.4-.8,6.63-3.03,9.36-2.39,2.93-5.55,4.51-9.23,4.67-5.99.25-12,.25-18,.23-2.46,0-4.97-.1-7.38-.57C4.55,46.25.46,41.59.23,35.74-.03,29.11.06,22.47,0,15.83c-.02-2.28.14-4.54.86-6.72C2.49,4.16,5.98,1.28,11.05.45,13.36.06,15.74.04,18.1.02c5.22-.04,10.44-.01,15.65.06,2.34.03,4.64.42,6.77,1.45,4.5,2.18,6.82,5.93,7.2,10.78.31,3.91.26,11.78.21,11.78ZM23.99,4.28c-3.63.1-7.3.14-10.97.32-4.84.24-8.31,3.51-8.43,8.33-.17,7.39-.15,14.78,0,22.17.08,3.98,2.55,6.94,6.15,7.88,1.23.32,2.52.53,3.79.53,6.82.01,13.63.03,20.45-.13,4.02-.09,7.03-2.51,7.99-6.14.33-1.22.53-2.52.54-3.78.02-6.75.06-13.51-.12-20.26-.13-5.09-3.51-8.37-8.61-8.62-3.58-.18-7.16-.21-10.79-.31Z"
                    />
                    <path
                      className="cls-1"
                      d="m36.38,23.96c.03,6.79-5.55,12.41-12.37,12.44-6.8.03-12.42-5.55-12.45-12.36-.03-6.8,5.55-12.41,12.37-12.44,6.8-.03,12.42,5.54,12.45,12.36Zm-12.44-7.95c-4.66.17-7.97,3.7-7.94,8.07.03,4.43,3.71,8.02,8.01,7.91,4.67-.12,7.97-3.7,7.94-8.06-.03-4.43-3.71-7.98-8.01-7.92Z"
                    />
                    <path
                      className="cls-1"
                      d="m39.72,11.21c0,1.65-1.3,2.9-2.96,2.87-1.63-.02-2.85-1.26-2.85-2.89,0-1.66,1.26-2.92,2.92-2.92,1.65,0,2.91,1.28,2.9,2.94Z"
                    />
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/" aria-label={t("links.medium")}>
                  <svg
                    id="Ebene_1"
                    data-name="Ebene 1"
                    className="iconFooter"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200.95 113.8"
                    width={32}
                  >
                    <path
                      className="cls-1"
                      d="m56.88,113.8C25.26,113.7.01,88.23,0,56.91-.01,25.53,25.29-.08,57.04,0c31.72.08,56.86,25.77,56.75,57.09-.11,31.24-25.31,56.61-56.91,56.71Z"
                    />
                    <path
                      className="cls-1"
                      d="m118.1,56.54c.1-2.19.17-4.75.34-7.3.17-2.5.48-4.99.87-7.47.8-5.08,1.99-10.07,3.73-14.91,1.58-4.4,3.56-8.59,6.19-12.47,2-2.95,4.32-5.6,7.19-7.74,2.22-1.66,4.65-2.89,7.38-3.51,3.49-.8,6.85-.38,10.11,1.01,3.1,1.33,5.68,3.38,7.97,5.83,2.65,2.83,4.74,6.05,6.51,9.49,2.6,5.05,4.4,10.39,5.69,15.91.84,3.6,1.44,7.24,1.84,10.91.49,4.57.65,9.15.51,13.74-.09,2.94-.32,5.87-.68,8.78-.66,5.4-1.76,10.7-3.42,15.88-1.32,4.13-3,8.11-5.18,11.87-1.95,3.36-4.25,6.46-7.15,9.07-2.19,1.97-4.63,3.55-7.44,4.51-2.05.71-4.17,1-6.34.85-2.95-.2-5.66-1.18-8.16-2.74-2.81-1.75-5.14-4.01-7.18-6.59-2.81-3.56-4.95-7.51-6.68-11.68-1.87-4.48-3.23-9.12-4.16-13.88-.42-2.17-.8-4.36-1.11-6.55-.36-2.55-.57-5.12-.68-7.7-.07-1.65-.11-3.3-.17-5.32Z"
                    />
                    <path
                      className="cls-1"
                      d="m200.95,56.48c-.08,3.12-.12,6.66-.28,10.2-.13,3.05-.35,6.11-.62,9.15-.41,4.67-1.02,9.32-1.94,13.92-.66,3.3-1.45,6.57-2.68,9.71-.57,1.46-1.22,2.88-2.17,4.14-.44.6-.95,1.13-1.59,1.53-.89.54-1.78.56-2.68.02-.73-.43-1.27-1.04-1.76-1.72-.85-1.18-1.45-2.49-1.99-3.83-1-2.51-1.7-5.11-2.29-7.74-.71-3.18-1.25-6.4-1.67-9.64-.45-3.42-.78-6.86-1.03-10.3-.48-6.54-.6-13.08-.47-19.63.06-2.9.2-5.8.37-8.7.16-2.67.38-5.35.65-8.01.52-5.04,1.27-10.05,2.46-14.98.59-2.42,1.28-4.81,2.26-7.11.57-1.33,1.21-2.62,2.16-3.73.31-.36.66-.7,1.05-.99,1.04-.76,2.2-.73,3.24.05.6.45,1.07,1.01,1.5,1.62,1.05,1.53,1.76,3.22,2.37,4.96,1.11,3.15,1.88,6.39,2.46,9.67.46,2.64.87,5.28,1.22,7.94.29,2.21.48,4.44.66,6.66.2,2.45.38,4.9.48,7.35.13,3,.18,6.01.27,9.44Z"
                    />
                  </svg>
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
        <div>
          <Link className="link" to="/impressum">
            {t("footer.imprint")}
          </Link>
          <Link className="link" to="/datenschutz">
            {t("footer.privacypolicy")}
          </Link>
        </div>
      </section>
    </footer>
  );
}
