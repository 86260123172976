import React from "react";

export default function Impressum() {
  return (
    <main id="imprint">
      <h1>Impressum</h1>
      <section style={{ flexDirection: "column", alignItems: "flex-start" }}>
        <h2 lang="de" style={{ fontSize: "1.5em" }}>
          Angaben gemäß §5 TMG
        </h2>
        <p style={{ textAlign: "left" }}>
          Verena Schwachmeyer<br></br>
          Bietzkestr. 3<br></br>
          10315 Berlin<br></br>
        </p>
        <p>
          Fon: Null Eins Sieben Sechs Drei Null Eins Sieben Zwei Fünf Sechs
          Sechs
        </p>
        <p>post(at)schwachmeyer-gottschalk.de</p>
        <h2 style={{ fontSize: "1.5em" }}>
          Umsatzsteuer-Identifikationsnummer
        </h2>
        <p>Steueridentifikationsnummer: DE363017090</p>
        <h2 style={{ fontSize: "1.5em" }}>Haftungsausschluss</h2>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen. <br></br>
          <br></br>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </p>
        <h3 style={{ fontSize: "1.1em" }}>Haftung für Links</h3>
        <p>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. <br></br>
          <br></br>Eine permanente inhaltliche Kontrolle der verlinkten Seiten
          ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
          zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
          derartige Links umgehend entfernen.
        </p>
        <h3 style={{ fontSize: "1.1em" }}>Urheberrecht</h3>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.{" "}
          <br></br>
          <br></br>Soweit die Inhalte auf dieser Seite nicht vom Betreiber
          erstellt wurden, werden die Urheberrechte Dritter beachtet.
          Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten
          Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
          bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
        <p id="impressumQuelle">Quelle: www.e-recht24.de</p>
      </section>
    </main>
  );
}
