import React, { useState } from "react";
import ContactField from "../components/ContactField";
import { useTranslation } from "react-i18next";
import { handleChange } from "../utils/functions";
import "../styles/loading.css";
import BarLoader from "react-spinners/BarLoader";

export default function Contact() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: "Post",
    aim: "",
    name: "",
    email: "",
    message: "",
  });

  const statename = formData;
  const funcname = setFormData;

  const override = {
    margin: "auto",
  };

  return (
    <main id="main">
      <section id="section1" tabIndex={0}>
        <div
          className="background right"
          title={t("kontakt.section1.imagealt")}
          style={{
            backgroundPosition: "center",
            backgroundImage:
              "url(/images/20231103_Michael_Gottschalk1794_sw.webp)",
          }}
        >
          <div className="layer right"></div>
        </div>
        <div
          id="h1Box"
          className="textBox yellowBox"
          tabIndex={0}
          style={{ width: "60%", height: "100%" }}
        >
          <h1>{t("kontakt.section1.h1")}</h1>
          {/* <h2>{t("kontakt.section1.h2")}</h2> */}
          <h3>{t("kontakt.section1.h3")}</h3>
          <p>{t("kontakt.section1.p")}</p>
        </div>
      </section>
      <section id="section2" tabIndex={0}>
        <div className="textBox yellowBox">
          <h2>{t("kontakt.section2.h2")}</h2>
          <p>{t("kontakt.section2.p")}</p>
          <form>
            <label style={{ marginTop: "20px" }}>
              {t("kontakt.section2.label")}
            </label>
            <select
              id="aim"
              name="aim"
              onChange={(e) => {
                handleChange(e, funcname, statename);
              }}
              required
            >
              <option value=""></option>
              <option value="Neuentwicklung">
                {t("kontakt.section2.formoption1")}
              </option>
              <option value="Überprüfung Webseite">
                {t("kontakt.section2.formoption2")}
              </option>
              <option value="Re-Design">
                {t("kontakt.section2.formoption3")}
              </option>
              <option value="Allgemeinberatung">
                {t("kontakt.section2.formoption4")}
              </option>
              <option value="sonstige Anfrage">
                {t("kontakt.section2.formoption5")}
              </option>
            </select>
          </form>
        </div>
        <div className="textBox yellowBox">
          <h3> {t("kontakt.form.h3")}</h3>
          <ContactField
            funcname={funcname}
            statename={statename}
            setLoading={setLoading}
            loading={loading}
          />
          {loading && (
            <div className="loadingContainer">
              <BarLoader
                className="loadingSpinner"
                loading={loading}
                cssOverride={override}
                size={100}
                aria-busy="true"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <p>Mail wird gesendet...</p>
            </div>
          )}
        </div>
      </section>
    </main>
  );
}
