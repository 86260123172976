import React from "react";
import { IoIosSwitch, IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import "../styles/switchbar.css";

function SwitchMenu({ switchOpen, setSwitchOpen }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="switchMenuContainer">
        <button
          className="switchMenuButton"
          onClick={() => setSwitchOpen(!switchOpen)}
          aria-label="Menü"
        >
          {switchOpen ? (
            <IoMdClose className="switchbuttonIcon" fontSize="1.8em" />
          ) : (
            <IoIosSwitch className="switchbuttonIcon" fontSize="1.8em" />
          )}
        </button>
        <p style={{ margin: 0, padding: 0 }}>{t("switchbar.menu")}</p>
      </div>
    </>
  );
}

export default SwitchMenu;
