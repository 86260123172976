import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Table from "../components/Table";
import { useTranslation } from "react-i18next";
import { ColorThemeContext } from "../App";
import { Scroll2Element } from "../utils/functions";
import "../styles/arrows.css";

// TO BE IMPLEMENTED: ADD TRANSLATIONS!!

export default function Accessibility() {
  const { t } = useTranslation();
  const [colorTheme] = useContext(ColorThemeContext);
  console.log(colorTheme);

  const numbers = [
    {
      number: t("barrierefreiheit.numbers.num1"),
      caption: t("barrierefreiheit.numbers.text1"),
      aria: t("barrierefreiheit.numbers.num1"),
    },
    {
      number: t("barrierefreiheit.numbers.num2"),
      caption: t("barrierefreiheit.numbers.text2"),
      aria: t("barrierefreiheit.numbers.num2"),
    },
    {
      number: t("barrierefreiheit.numbers.num3"),
      caption: t("barrierefreiheit.numbers.text3"),
      aria: t("barrierefreiheit.numbers.num3"),
    },
    {
      number: t("barrierefreiheit.numbers.num4"),
      caption: t("barrierefreiheit.numbers.text4"),
      aria: t("barrierefreiheit.numbers.aria4"),
    },
    {
      number: t("barrierefreiheit.numbers.num5"),
      caption: t("barrierefreiheit.numbers.text5"),
      aria: t("barrierefreiheit.numbers.aria5"),
    },
    {
      number: t("barrierefreiheit.numbers.num6"),
      caption: t("barrierefreiheit.numbers.text6"),
      aria: t("barrierefreiheit.numbers.aria6"),
    },
  ];

  const impairments = [
    {
      id: "visual",
      h2: t("barrierefreiheit.impairments.visual.h2"),
      h3: t("barrierefreiheit.impairments.visual.h3"),
      p: t("barrierefreiheit.impairments.visual.text"),
      listitem1: t("barrierefreiheit.impairments.visual.listitem1"),
      listitem2: t("barrierefreiheit.impairments.visual.listitem2"),
      listitem3: t("barrierefreiheit.impairments.visual.listitem3"),
      listitem4: t("barrierefreiheit.impairments.visual.listitem4"),
      imageurl: "url(/images/barrierefreiheit_section_05_visuell.jpg)",
      title: t("barrierefreiheit.impairments.visual.imagetitle"),
      layerwidth: "30%",
      // "photo showing that glasses make the letter on an eye test board visible",
    },
    {
      id: "auditive",
      h2: t("barrierefreiheit.impairments.auditive.h2"),
      h3: t("barrierefreiheit.impairments.auditive.h3"),
      p: t("barrierefreiheit.impairments.auditive.text"),
      listitem1: t("barrierefreiheit.impairments.auditive.listitem1"),
      listitem2: t("barrierefreiheit.impairments.auditive.listitem2"),
      listitem3: t("barrierefreiheit.impairments.auditive.listitem3"),
      listitem4: t("barrierefreiheit.impairments.auditive.listitem4"),
      imageurl: "url(/images/barrierefreiheit_section_05_auditiv.jpg)",
      title: t("barrierefreiheit.impairments.auditive.imagetitle"),
      layerwidth: "30%",
      // "photo showing that glasses make the letter on an eye test board visible",
    },
    {
      id: "motoric",
      h2: t("barrierefreiheit.impairments.motoric.h2"),
      h3: t("barrierefreiheit.impairments.motoric.h3"),
      p: t("barrierefreiheit.impairments.motoric.text"),
      listitem1: t("barrierefreiheit.impairments.motoric.listitem1"),
      listitem2: t("barrierefreiheit.impairments.motoric.listitem2"),
      listitem3: t("barrierefreiheit.impairments.motoric.listitem3"),
      listitem4: t("barrierefreiheit.impairments.motoric.listitem4"),
      imageurl: "url(/images/barrierefreiheit_section_05_motorisch.jpg)",
      title: t("barrierefreiheit.impairments.motoric.imagetitle"),
      layerwidth: "80%",
      // "photo showing that glasses make the letter on an eye test board visible",
    },
    {
      id: "cognitive",
      h2: t("barrierefreiheit.impairments.cognitive.h2"),
      h3: t("barrierefreiheit.impairments.cognitive.h3"),
      p: t("barrierefreiheit.impairments.cognitive.text"),
      listitem1: t("barrierefreiheit.impairments.cognitive.listitem1"),
      listitem2: t("barrierefreiheit.impairments.cognitive.listitem2"),
      listitem3: t("barrierefreiheit.impairments.cognitive.listitem3"),
      listitem4: t("barrierefreiheit.impairments.cognitive.listitem4"),
      imageurl: "url(/images/barrierefreiheit_section_05_kognitiv.jpg)",
      title: t("barrierefreiheit.impairments.cognitive.imagetitle"),
      layerwidth: "60%",
      // "photo showing that glasses make the letter on an eye test board visible",
    },
  ];

  const linkData = [
    {
      id: "visual",
      linkText: t("barrierefreiheit.section2.link1"),
      title: t("barrierefreiheit.section2.title1"),
    },
    {
      id: "auditive",
      linkText: t("barrierefreiheit.section2.link2"),
      title: t("barrierefreiheit.section2.title2"),
    },
    {
      id: "motoric",
      linkText: t("barrierefreiheit.section2.link3"),
      title: t("barrierefreiheit.section2.title3"),
    },
    {
      id: "cognitive",
      linkText: t("barrierefreiheit.section2.link4"),
      title: t("barrierefreiheit.section2.title4"),
    },
  ];

  return (
    <main id="main">
      <section id="section1">
        <div
          className="background right"
          title={t("barrierefreiheit.section1.imagealt")}
          style={{
            objectPosition: "right",
            backgroundImage: "url(/images/barrierefreiheit_section_00.jpg)",
          }}
        >
          <div className="layer right"></div>
        </div>
        <div
          id="h1Box"
          className="textBox yellowBox"
          tabIndex={0}
          style={{ width: "60%", height: "100%" }}
        >
          <h1>{t("barrierefreiheit.section1.h1")}</h1>
          <h2>{t("barrierefreiheit.section1.h2")}</h2>
          {/* <button>{t("barrierefreiheit.section1.button")}</button> */}
        </div>
      </section>
      <section id="section2">
        <div className="textBox" tabIndex={0}>
          <h2>{t("barrierefreiheit.section2.h2")}</h2>
          <h3>{t("barrierefreiheit.section2.h3")}</h3>
          <p>{t("barrierefreiheit.section2.p1")}</p>
          <p>{t("barrierefreiheit.section2.p2")}</p>
        </div>
        <div className="textBox yellowBox" tabIndex={0}>
          <ul>
            {linkData.map((item) => (
              <li key={item.id}>
                <button
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    margin: "0",
                    padding: "0",
                  }}
                  onClick={(e) => Scroll2Element(e, `#${item.id}`)}
                >
                  <p
                    title={item.title}
                    className={`animated-arrow ${
                      colorTheme === "light" ? "light-theme" : "dark-theme"
                    }`}
                    style={{ color: "var(--body-text)" }}
                  >
                    <span className="the-arrow -left">
                      <span className="shaft"></span>
                    </span>
                    <span className="main">
                      <span className="text">{item.linkText}</span>
                      <span className="the-arrow -right">
                        <span className="shaft"></span>
                      </span>
                    </span>
                  </p>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section id="section3" tabIndex={0}>
        <div className="textBox yellowBox" style={{ width: "66%" }}>
          <h2>{t("barrierefreiheit.section3.h2")}</h2>
          <h3>{t("barrierefreiheit.section3.h3")}</h3>
          <p>{t("barrierefreiheit.section3.p")}</p>
        </div>
      </section>
      <section
        id="section4"
        style={{ justifyContent: "space-evenly" }}
        tabIndex={0}
      >
        {numbers &&
          numbers.map((item, index) => {
            return (
              <div className="numberItem" tabIndex={0}>
                <div className="hidden">{item.aria}</div>
                <p className="number" aria-hidden={true}>
                  {item.number}
                </p>
                <p className="numberCaption">{item.caption}</p>
              </div>
            );
          })}
      </section>

      {/* Impairments */}
      {impairments &&
        impairments.map((item, index) => {
          const side = index % 2 === 0 ? "left" : "right";
          return (
            <section
              id={item.id}
              tabIndex={0}
              style={
                side === "left"
                  ? null
                  : { flexDirection: "row-reverse", justifyContent: "flex-end" }
              }
            >
              <div
                className="textBox yellowBox"
                style={{ width: "50%", height: "100%" }}
              >
                <h2>{item.h2}</h2>
                <h3>{item.h3}</h3>
                <p>{item.p}</p>
                <ul>
                  <li>{item.listitem1}</li>
                  <li>{item.listitem2}</li>
                  <li>{item.listitem3}</li>
                  <li>{item.listitem4}</li>
                </ul>
              </div>
              <div
                className={`background ${side}`}
                title={item.title}
                style={{
                  width: "50%",
                  backgroundImage: `${item.imageurl}`,
                }}
              >
                <div
                  className={`layer ${side}`}
                  style={{ width: item.layerwidth }}
                ></div>
              </div>
            </section>
          );
        })}

      <section id="section5" tabIndex={0}>
        <div className="textBox" style={{ width: "40%" }}>
          <h2>{t("barrierefreiheit.section5.h2")}</h2>
          <p>{t("barrierefreiheit.section5.p1")}</p>
          <p>{t("barrierefreiheit.section5.p2")}</p>
          <Link to="/kontakt" className="button buttonlink">
            {t("barrierefreiheit.section5.button")}
          </Link>
        </div>
        <Table />
      </section>

      <section
        id="section6"
        tabIndex={0}
        style={{ width: "100%", minHeight: "250px" }}
      >
        <div className="summaryBox" tabIndex={0}>
          <span>{t("barrierefreiheit.section6.p")}</span>
        </div>
        <div
          className="background left"
          title={t("barrierefreiheit.section6.imagealt")}
          style={{
            width: "30%",
            minHeight: "250px",
            backgroundImage: "url(/images/barrierefreiheit_section_04.jpg)",
          }}
        >
          <div className="layer left"></div>
        </div>
      </section>
      <section id="section7" tabIndex={0}>
        <div className="textBox">
          <h2>{t("barrierefreiheit.section7.h2")}</h2>
          <p>{t("barrierefreiheit.section7.p")}</p>
          <ul style={{ fontFamily: "HankenGrotesk-SemiBoldItalic" }}>
            <li>
              <strong>{t("barrierefreiheit.section7.item1")}</strong>
            </li>
            <li>
              <strong>{t("barrierefreiheit.section7.item2")}</strong>
            </li>
            <li>
              <strong>{t("barrierefreiheit.section7.item3")}</strong>
            </li>
            <li>
              <strong>{t("barrierefreiheit.section7.item4")}</strong>
            </li>
          </ul>
        </div>
        <div className="textBox yellowBox">
          <p>{t("barrierefreiheit.section7b.p")}</p>
          <ol>
            <li>{t("barrierefreiheit.section7b.A")}</li>
            <li>{t("barrierefreiheit.section7b.AA")}</li>
            <li>{t("barrierefreiheit.section7b.AAA")}</li>
          </ol>
        </div>
      </section>
      <section id="section8" tabIndex={0}>
        <div className="textBox">
          <h2>{t("barrierefreiheit.section8.h2")}</h2>
          <p>{t("barrierefreiheit.section8.p")}</p>
          <Link to="/kontakt" className="button buttonlink">
            {t("barrierefreiheit.section8.button")}
          </Link>
        </div>
        <div className="textBox yellowBox">
          <h3>{t("barrierefreiheit.section8b.h3a")}</h3>
          <p>{t("barrierefreiheit.section8b.pa")}</p>
          <h3>{t("barrierefreiheit.section8b.h3b")}</h3>
          <ul>
            <li>
              <span>
                <img
                  src="/images/arrow_short.svg"
                  alt="Pfeil"
                  height="10px"
                  style={{ marginRight: "10px" }}
                ></img>
              </span>
              {t("barrierefreiheit.section8b.item1")}
            </li>
            <li>
              {" "}
              <span>
                <img
                  src="/images/arrow_short.svg"
                  alt="Pfeil"
                  height="10px"
                  style={{ marginRight: "10px" }}
                ></img>
              </span>
              {t("barrierefreiheit.section8b.item2")}
            </li>
            <li>
              {" "}
              <span>
                <img
                  src="/images/arrow_short.svg"
                  alt="Pfeil"
                  height="10px"
                  style={{ marginRight: "10px" }}
                ></img>
              </span>
              {t("barrierefreiheit.section8b.item3")}
            </li>
            <li>
              {" "}
              <span>
                <img
                  src="/images/arrow_short.svg"
                  alt="Pfeil"
                  height="10px"
                  style={{ marginRight: "10px" }}
                ></img>
              </span>
              {t("barrierefreiheit.section8b.item4")}
            </li>
            <li>
              {" "}
              <span>
                <img
                  src="/images/arrow_short.svg"
                  alt="Pfeil"
                  height="10px"
                  style={{ marginRight: "10px" }}
                ></img>
              </span>
              {t("barrierefreiheit.section8b.item5")}
            </li>
          </ul>
        </div>
      </section>
    </main>
  );
}
