import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // this is mandatory so that is it accessible in all components!!
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: "de",
    debug: true,
    fallbackLng: "de",
    react: {
      transSupportBasicHtmlNodes: true,
      transWrapTextNodes: "",
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          skiplink: {
            text: `skip to main content`,
          },
          navigation: {
            home: "Home",
            acc: "Accessibility",
            sus: "Sustainability",
            cont: "Contact",
          },
          links: {
            linkedin: "go to LinkedIn",
            insta: "go to Instagram",
            medium: "go to Medium",
          },
          switchbar: {
            small: "small font size",
            med: "medium font size",
            large: "large font size",
            light: "light",
            dark: "dark",
            arialight: "light mode",
            ariadark: "dark mode",
            de: `language: German`,
            en: `language: English`,
            left: `left`,
            right: `right`,
            menu: `Menu`,
          },
          form: {
            name: `name`,
            email: `e-mail`,
            message: `your message`,
            button: `send`,
          },
          footer: {
            logopath: `/images/logo_bild_wortmarke_white.svg`,
            location: `Berlin and Leipzig, Germany`,
            imprint: `Imprint`,
            privacypolicy: "Privacy Policy",
          },
          startseite: {
            section1: {
              h1: `Accessible web applications - your way to innovative solutions!`,
              h2: `Optimal accessibility for everyone - is your website prepared?`,
              button: `contact us`,
              ariabutton: `contact us`,
              imagealt: `Three people are laughing in front of a computer`,
            },
            section2: {
              h2: `Accessibility in digital space`,
              h3: `Usability for all`,
              p: `Accessibility enables websites, applications, and digital products to be designed in a way that they can be understood and used or operated seamlessly by every individual. However, there are people with specific requirements due to visual, auditory, motor, or cognitive impairments. In this context, it is crucial to create digital content that is accessible to all.`,
              button: `more info`,
              ariabutton: `more about accessibility`,
              stackdeck: `Stack Deck`,
            },
            section3: {
              h2: `Sustainable websites and environmental protection in the digital age`,
              h3: ``,
              p: `In an era where environmental protection and sustainability are gaining increasing relevance, attention to the digital space becomes a crucial necessity. Sustainable websites play a key role in this, as they not only minimize environmental impacts but also can raise awareness of a sustainable digital footprint. Discover how designing websites based on sustainable principles can initiate positive changes in the digital environment.`,
              button: `more info`,
              ariabutton: `more about sustainability`,
            },
            section3b: {
              h2: `Our Services`,
              item1: `accessible and sustainable web development`,
              item2: `Real-life testing through experts such as deaf or blind individuals`,
              item3: `redesign and refactoring of existing web presence `,
              item4: `development of innovative apps for mentally challenged people`,
            },
            sectionVerena: {
              intro: ``,
              function: `Your partner for development, software architecture & strategy`,
              description: `Verena loves diving into the digital world. She's all about making her work meaningful and helpful. This passion goes way back to her days as a physiotherapist and clinical researcher. Now, she's using her medical know-how to build websites that everyone can use. Verena's got a sharp mind and a knack for solving digital puzzles. She enjoys adding real value by thinking smart and getting creative with features. Her journey is like a mix of playing with pixels and code, breaking down the walls between medicine and tech.`,
            },
            sectionMicha: {
              intro: ``,
              function: `Your partner for design, conception & development`,
              description: `With a smile on his face and a passion for sustainable design, Michael has transitioned from being the former Head of Design at a Leipzig agency to the world of freelance communication designers and project developers. His focus is deliberately on projects that are not only sustainable but also socially and culturally relevant. The central theme capturing all his attention is inclusion. In every of his creations, there's a belief that design should not only be aesthetically pleasing but also capable of making a positive impact in the world. Let's collaborate on projects that are not just beautiful but also make a positive difference!`,
            },
            linkedin: {
              aria: `Link to LinkedIn profile`,
            },
          },
          barrierefreiheit: {
            section1: {
              h1: `Mastering digital accessibility: Your key to an inclusive online experience!`,
              h2: `Websites, Apps and digital products operable for everyone`,
              button: `go to checklist`,
              link1: `Visual impairments`,
              link2: `Auditive impairments`,
              link3: `Motor deficits`,
              link4: `Cognitive impairments`,
              imagealt: `a young man with Down Syndrome is focussing on a computer screen`,
            },
            section2: {
              h2: `Digital inclusion:`,
              h3: `For accessible online spaces and respected diversity on the internet.`,
              p1: `The fundamental concept of accessibility in the digital space is that websites, applications, and other digital products are designed in a way that allows all people to easily understand, use, and navigate them. Especially individuals with various conditions, be it visual, auditory, motor, or cognitive, should be able to enjoy the same quality of user experience.`,
              p2: `There are people who face particular challenges due to such limitations. To better understand this, let's take a look at some specific examples:`,
              link1: `visual impairments`,
              title1: `Link scrolls down on page to section that explains visual challenges`,
              link2: `auditive impairments`,
              title2: `Link scrolls down on page to section that explains challenges with hearing`,
              link3: `motoric impairments`,
              title3: `Link scrolls down on page to section that explains motoric challenges`,
              link4: `cognitive impairments`,
              title4: `Link scrolls down on page to section that explains cognitive challenges`,
            },
            section3: {
              h2: `How many people are affected by a lack of accessibility?`,
              h3: `Accessibility affects more people than many think - a challenge for the many, not the few.`,
              p: `The listed numbers below represent only a fraction of the existing underlying conditions, of which there are many more. It is crucial to emphasize that these are individuals who are employed and fully integrated into life. They share the desire to participate in the digital life on the internet, just like all of us.`,
            },
            numbers: {
              num1: `19.4%`,
              text1: `of the people in Germany have hearing disabilities or are deaf`,
              num2: `10.4%`,
              text2: `have visual impairments`,
              num3: `9%`,
              text3: `aller Männer haben eine Form von Rot-Grün-Schwäche`,
              num4: `500,000`,
              aria4: 500000,
              text4: `suffer from epilepsy`,
              num5: `14,000`,
              aria5: 14000,
              text5: `with amputated upper extremity`,
              num6: `> 50,000`,
              aria6: `more than${50000}`,
              text6: `people with a half-sided or full paralysis (para/tetraplegia)`,
            },
            impairments: {
              visual: {
                h2: `Visual impairments`,
                h3: ``,
                text: `Diseases or difficulties with vision lead to, for example, the inability to perceive low color contrasts or read small font sizes. Blind individuals rely on the use of a screen reader or Braille board. A screen reader reads the contents of a page aloud, while a Braille board translates them into Braille. If websites are not optimally configured, these systems may not function properly. Examples: `,
                listitem1: "dyschromatopsia",
                listitem2: "cataract",
                listitem3: "macular degeneration",
                listitem4: "blindness",
                imagetitle:
                  "A look through glasses sharpens the view onto an eye test board.",
              },
              auditive: {
                h2: `Auditive impairments`,
                h3: ``,
                text: `With hearing difficulties, videos without subtitles cannot be understood. Furthermore, some websites use auditory cues to signal interactions, such as sending an email. Hearing-impaired individuals cannot perceive these signals. If there is no other visual feedback beyond the auditory signal, the individual remains uncertain whether or not the email has been successfully sent. Examples are:`,
                listitem1: "tinnitus",
                listitem2: "paracusis",
                listitem3: "acute hearing loss",
                listitem4: "complete deafness",
                imagetitle: "a deaf aid is being placed.",
              },
              motoric: {
                h2: `Motor impairments`,
                h3: ``,
                text: `People with motor impairments cannot use a computer mouse and rely on the keyboard for navigation. Some can use a mouse but face challenges in accurately clicking on controls such as buttons or links when they are too small. Here are a few examples: `,
                listitem1: "reduced fine motor skills such as hand tremors",
                listitem2: "paralysis (spacticity, flaccid or tremor-related)",
                listitem3: "polyneuropathy",
                listitem4: "amputations of hand or arm",
                imagetitle: "A arm prosthesis is being checked",
              },
              cognitive: {
                h2: `Cognitive impairments`,
                h3: ``,
                text: `Many of us have visited a website and didn't understand the text content because it was too complex. This is how some individuals constantly feel. Furthermore, busy images or animations can be distracting or irritating. Strobe effects may even cause epileptic seizures. Additionally, unnecessary animations and effects increase the data rate, reducing sustainability. Examples: `,
                listitem1: "migraine",
                listitem2:
                  "concentration difficulties, easy distractibility, ADHS",
                listitem3: "reading difficulties",
                listitem4: "epilepsy",
                imagetitle:
                  "A young woman is sitting in front of a monitor trying to focus and rubbing her forehead",
              },
            },
            section5: {
              h2: `Accessiblity benefits us all!`,
              p1: `Barrierefreiheit ist mehr als nur eine Anpassung an dauerhafte Einschränkungen - sie betrifft jeden, unabhängig davon, ob die Beeinträchtigung permanent oder temporär ist. Jeder Mensch kann zeitweise oder durch bestimmte Situationen bedingt eingeschränkt sein.`,
              p2: `Wir verstehen, dass Einschränkungen vielseitig sein können. Deshalb legen wir besonderen Fokus darauf, digitale Inhalte und Ressourcen so zu gestalten, dass sie für alle zugänglich sind. Ob es nun um dauerhafte Herausforderungen oder vorübergehende Beeinträchtigungen geht - unsere Lösungen sind darauf ausgerichtet, die Barrierefreiheit für jeden Nutzer zu gewährleisten.`,
              button: `inquire an analysis`,
            },
            table: {
              situative: `Situative`,
              temporary: `Temporary`,
              permanent: `Permanent`,
              visual: `Vision`,
              auditive: `Hearing`,
              motoric: `Motor Control`,
              physical: `Physical Ability`,
              cognitive: `Cognition`,
              cell1: `Bright Light`,
              cell2: `Cataract`,
              cell3: `Blind`,
              cell4: `Loud Environment`,
              cell5: `Defective Loudspeaker`,
              cell6: `Deaf`,
              cell7: `Alcohol`,
              cell8: `Defective Display`,
              cell9: `Tremor`,
              cell10: `Baby in Arm`,
              cell11: `Fracture`,
              cell12: `Amputation`,
              cell13: `Distress`,
              cell14: `Unconcentrated`,
              cell15: `Non-Native Speaker`,
            },
            section6: {
              p: `Accessibility is not just a technical requirement but, above all, a means to make digital spaces accessible to everyone. By integrating such principles, the internet becomes a place that respects and promotes the diversity and uniqueness of each user.`,
              imagealt: `A person uses a Braille board.`,
            },
            section7: {
              h2: `Guidelines for accessible websites`,
              p: `Practical instructions can be found in the guidelines for accessible web content (WCAG = Web Content Accessibility Guidelines) 2.1. These guidelines were first published by the World Wide Web Consortium (W3C) in 1999 and have been updated since then. They establish that a website should adhere to four fundamental principles:`,
              item1: `Perceivable`,
              item2: `Operable`,
              item3: `Understandable`,
              item4: `Robust`,
            },
            section7b: {
              p: `Currently, the Web Content Accessibility Guidelines (WCAG) 2.1 consist of a total of 78 success criteria aimed at breaking down barriers. These criteria are divided into three levels. The WCAG conformity of a website is assessed based on the fulfillment of specific criteria and levels, allowing for a differentiated evaluation of its accessibility.`,
              A: `Level A: Minimum requirements`,
              AA: `Level AA: Legally required requirements of medium height`,
              AAA: `Level AAA: Maximum requirements with advanced criteria such as sign language.`,
            },
            section8: {
              h2: `Who is obligated to establish an accessible online presence?`,
              p: `Since 2019, public institutions, including authorities, municipal administrations, government bodies, police, and educational institutions, are legally obligated to make their web presences accessible. This obligation is set forth by the EU Directive 2016/2102 and the Disability Equality Act of April 27, 2002. Starting from June 2025, private companies in online commerce are also required to ensure accessible access. However, there are exceptions, such as for micro-enterprises with fewer than 10 employees and an annual turnover of less than 2 million euros. These regulations are established by the Barrier-Free Accessibility Strengthening Act (BFSG) and the Act to Implement Directive (EU) 2019/882.`,
              button: `check`,
            },
            section8b: {
              h3a: `What level of compliance must my company's website achieve?`,
              pa: `According to the EN 301 549 standard, websites are required to meet the AA criteria.`,
              h3b: `What benefits do I gain by making my website accessible?`,
              item1: `Increased reach by catering to a larger potential customer base`,
              item2: `Improved search engine optimization (SEO)`,
              item3: `Setting a positive example`,
              item4: `Enhanced accessibility and user-friendliness`,
              item5: `Meeting high technical standards`,
            },
          },
          navbar: {
            n1: `homepage`,
            n2: "Accessibility",
            n3: "Sustainability",
            n4: `Contact`,
          },
          burgermenu: {
            open: `open and close menu`,
          },
          kontakt: {
            section1: {
              h1: `Contact us!`,
              h2: `We would be happy to support your project`,
              h3: `We will be happy to help you. Send us a mail and we will get back to you as soon as we can.`,
              p: `Whether you already have a web presence or not, an accessibility check of a website is just as possible as its revision. Furthermore, we can help you re-design your website. A completely new development from scratch is also feasible - just reach out to us!`,
              imagealt: `Michael Gottschalk und Verena Schwachmeyer`,
            },
            section2: {
              h2: "How can we help you?",
              p: `The better we know what you need, the better we can meet your needs.`,
              label: `Please provide your motivation:`,
              formoption1: `New Development`,
              formoption2: `Check/revision of an existing website`,
              formoption3: `Re-design`,
              formoption4: `General inquiry`,
              formoption5: `Other`,
            },
            form: {
              h3: `Your message:`,
              name: `Name`,
              mail: `E-mail Address`,
              message: `Message`,
              button: `Send`,
              sending: "sending mail...",
            },
          },
          modal: {
            header: "Thanks for your message.",
            text: "Your message has been successfully sent. We will get back to yoo soon.",
            error:
              "Ooops! Something went wrong - we apologize. Please try again later.",
          },
        },
      },
      de: {
        translation: {
          skiplink: {
            text: `Springe zum Hauptinhalt`,
          },
          navigation: {
            home: "Startseite",
            acc: "Barrierefreiheit",
            sus: "Nachhaltigkeit",
            cont: "Kontakt",
          },
          links: {
            linkedin: "gehe zu LinkedIn",
            insta: "gehe zu Instagram",
            medium: "gehe zu Medium",
          },
          switchbar: {
            small: "kleine Schrift",
            med: "mittlere Schriftgröße",
            large: "große Schrift",
            light: "hell",
            dark: "dunkel",
            arialight: "heller Modus",
            ariadark: "dunkler Modus",
            de: `Sprache: deutsch`,
            en: `Sprache: englisch`,
            left: `links`,
            right: `rechts`,
            menu: `Menü`,
          },
          form: {
            name: `Name`,
            email: `E-Mail-Adresse`,
            message: `Ihre Nachricht an mich`,
            button: `Absenden`,
          },
          footer: {
            logopath: `/images/logo_bild_wortmarke_deutsch_white.svg`,
            location: `Berlin und Leipzig`,
            imprint: `Impressum`,
            privacypolicy: "Datenschutz",
          },
          startseite: {
            section1: {
              h1: `Barrierefreie Webauftritte - Ihr Weg zu innovativen Lösungen!`,
              h2: "Maximale Zugänglichkeiten für Jeden - Ist Ihre Webseite bereit?",
              button: `Kontakt aufnehmen`,
              ariabutton: `Kontakt aufnehmen`,
              imagealt: `Drei Menschen sitzen vor einem PC und lachen`,
            },
            section2: {
              h2: `Barrierefreiheit im digitalen Raum`,
              h3: `Zugänglichkeit für Alle`,
              p: `Barrierefreiheit ermöglicht, dass
              Webseiten, Anwendungen und digitale Produkte von jeder Person verstanden und problemlos genutzt oder
              bedient werden können: Es gibt Menschen, die aufgrund von
              visuellen, auditiven, motorischen oder kognitiven Einschränkungen
              besondere Anforderungen haben. Für diese Menschen ist es
              entscheidend, digitale Inhalte so gut zu gestalten, dass sie für
              alle zugänglich sind.`,
              button: `Mehr Informationen`,
              ariabutton: `Mehr über digitale Barrierefreiheit`,
              stackdeck: `Technologien`,
            },
            section3: {
              h2: `Nachhaltige Webseiten und Umweltschutz im digitalen Zeitalter`,
              h3: ``,
              p: `In einer Ära, in der Umweltschutz und Nachhaltigkeit zunehmend an
              Relevanz gewinnen, wird die Aufmerksamkeit auf den digitalen Raum zu
              einer entscheidenden Notwendigkeit. Nachhaltige Webseiten nehmen
              dabei eine Schlüsselposition ein, indem sie nicht nur
              Umweltauswirkungen minimieren, sondern auch ein Bewusstsein für
              einen nachhaltigen digitalen Fußabdruck schaffen können. Wir hosten unsere Webseiten bei Anbietern, die ihre Server aus 100% Ökostrom betreiben. Durch einen gezielten Einsatz verschiedener Technologien und Dateiformate werden Webauftritte dabei möglichst klein gehalten, um den Datenverbrauch gering zu halten.`,
              button: `Mehr Informationen`,
              ariabutton: `Mehr über digitale Nachhaltigkeit`,
            },
            section3b: {
              h2: `Unsere Leistungen`,
              item1: `Barrierefreie und nachhaltige Webentwicklung`,
              item2: `Testung der Barrierefreiheit durch "echte Experten", also z.B. Gehörlose oder Blinde`,
              item3: `Redesign und Überarbeitung bestehender Onlineauftritte`,
              item4: `Entwicklung innovativer Apps für Menschen mit anderen Lernmöglichkeiten`,
            },
            sectionVerena: {
              intro: ``,
              function: `Ihre Ansprechpartnerin für Entwicklung, Software Architektur & Planung`,
              description: `Verena agiert mit Leidenschaft in der digitalen Welt. Ihr Antrieb liegt in der Sinnhaftigkeit und Gemeinnützigkeit ihrer Arbeit. Ihr Werdegang von der Physiotherapie über die klinische Forschung hin zur Webentwicklung spiegelt eine Reise wider, bei der sie nicht nur Pixel und Code manipuliert, sondern auch die Grenzen zwischen Medizin und Technologie aufhebt. Ihr medizinisches Wissen setzt sie nun ein, um barrierefreie Webauftritte zu entwickeln. Verenas scharfer Verstand und ihre Fähigkeit zum analytischen Denken machen sie zu einer Architektin von digitalen Lösungen. Sie liebt es, mit strukturiertem Denken und kreativen Funktionalitäten echten Mehrwert zu schaffen.`,
            },
            sectionMicha: {
              intro: ``,
              function: `Ihr Ansprechpartner für Gestaltung, Konzeption & Entwicklung`,
              description: `Mit einem Lächeln auf den Lippen und einer Passion für nachhaltiges Design ist Michael als ehemaliger Head of Design einer Leipziger Agentur in die Welt der freiberuflichen Kommunikationsdesigner und Projektentwickler zurückgekehrt. Sein Fokus liegt dabei ganz bewusst auf Projekten, die nicht nur nachhaltig, sondern auch gesellschaftlich und sozial relevant sind. Das Thema, dem er seine ganze Aufmerksamkeit schenkt, lautet Inklusion. In jeder seiner Kreationen steckt die Überzeugung, dass Design nicht nur ästhetisch ansprechend sein sollte, sondern auch eine positive Wirkung in der Welt entfalten kann. Lassen Sie uns gemeinsam an Projekten arbeiten, die nicht nur schön sind, sondern auch Gutes bewirken!`,
            },
            linkedin: {
              aria: `Link zum LinkedIn Profil`,
            },
          },
          barrierefreiheit: {
            section1: {
              h1: `Digitale Barrierefreiheit meistern: Ihr Schlüssel zum inklusiven Online-Erlebnis!`,
              h2: `Webseiten, Anwendungen und digitale Produkte für alle zugänglich gestalten`,
              button: `zur Checkliste`,
              imagealt: `Ein junger Mann mit Down-Syndrom schaut konzentriert auf einen Computerbildschirm`,
            },
            section2: {
              h2: `Digitale Inklusion:`,
              h3: `Für zugängliche Online-Räume und respektierte Vielfalt im Internet`,
              p1: `Die Grundidee von Barrierefreiheit im digitalen Raum ist, dass Webseiten, Anwendungen und andere digitale Produkte so gestaltet sind, dass sie von allen Menschen problemlos verstanden, genutzt und bedient werden können. Vor allem Menschen mit verschiedenen Erkrankungen, sei es visuell, auditiv, motorisch oder kognitiv, sollen die gleiche Qualität der Nutzungserfahrung genießen können.`,
              p2: ` Leider gibt es Menschen, die aufgrund solcher Einschränkungen vor besonderen Herausforderungen stehen. Um dies besser zu verstehen, werfen wir einen Blick auf einige konkrete Beispiele:`,
              link1: `Visuelle Einschränkungen`,
              title1: `Link scrollt auf der Seite herunter zum Abschnitt über über visuelle Herausforderungen`,
              link2: `Auditive Einschränkungen`,
              title2: `Link scrollt auf der Seite herunter zum Abschnitt über über Herausforderungen mit dem Hören`,
              link3: `Motorische Einschränkungen`,
              title3: `Link scrollt auf der Seite herunter zum Abschnitt über über motorische Herausforderungen`,
              link4: `Kognitive Einschränkungen`,
              title4: `Link scrollt auf der Seite herunter zum Abschnitt über über kognitive Herausforderungen`,
            },
            section3: {
              h2: `Wieviele Personen betrifft mangelnde Barrierefreiheit?`,
              h3: `Barrierefreiheit betrifft mehr Menschen, als viele denken - eine Herausforderung für die Vielen, nicht die Wenigen`,
              p: `Die nachfolgend aufgeführten Zahlen repräsentieren lediglich einen Bruchteil der vorhandenen Grunderkrankungen, von denen es viele weitere gibt. Es ist entscheidend zu betonen, dass es sich dabei um Arbeitstätige und vollständig im Leben integrierte Menschen handelt. Diese Menschen haben ebenso den Wunsch, am digitalen Leben im Netz teilzuhaben, genauso wie wir alle.`,
            },
            numbers: {
              num1: `19,4%`,
              text1: `der Menschen in Deutschland haben Hörschwierigkeiten oder sind taub`,
              num2: `10,4%`,
              text2: `haben eine Sehbehinderung`,
              num3: `9%`,
              text3: `aller Männer haben eine Form von Rot-Grün-Schwäche`,
              num4: `500.000`,
              aria4: 500000,
              text4: `leiden unter Epilepsie`,
              num5: `14.000`,
              aria5: 14000,
              text5: `haben eine Amputation der oberen Extremität`,
              num6: `> 50.000`,
              aria6: `mehr als ${50000}`,
              text6: `Menschen mit vollständigen oder halbseitigen Lähmungen (Para/Tetraplegie)`,
            },
            impairments: {
              visual: {
                h2: `Visuell`,
                h3: `Einschränkungen des Sehvermögens`,
                text: `Erkrankungen oder Schwierigkeiten mit dem Sehvermögen führen dazu,
                dass zum Beispiel geringe Farbkontraste nicht erkannt werden oder zu
                kleine Schriftgrößen nicht gelesen werden können. Blinde Personen
                sind auf die Nutzung eines Screen Readers oder Braille-Boards
                angewiesen. Ein Screen Reader liest die Inhalte einer Seite laut
                vor, während ein Braille-Board in Blindenschrift übersetzt. Wenn
                Webauftritte nicht optimal eingerichtet sind, funktionieren diese
                Systeme nicht. Dies kann zum Beispiel auftreten bei: `,
                listitem1: "Rot-Grün-Schwäche",
                listitem2: "Grauer Star",
                listitem3: "Maculadegeneration",
                listitem4: "Blindheit",
                imagetitle:
                  "Der Blick durch eine Brille macht die Buchstaben eines Sehtests scharf.",
              },
              auditive: {
                h2: `Auditiv`,
                h3: `Einschränkungen des Hörvermögens`,
                text: `Mit Hörproblemen können Videos ohne Untertitel nicht verstanden
                werden, so dass es wichtig ist, die Worte auch als Untertitel
                darzustellen. Weiterhin verwenden manche Webseiten akustische
                Effekte, um eine Interaktion zu signalisieren, zum Beispiel wenn
                eine Mail erfolgreich abgesendet wurde. Hörgeschädigte können dieses
                Signal nicht wahrnehmen. Wenn es darüber hinaus keinerlei optische
                Anzeichen für den erfolgreichen Versand einer Mail gibt, bleibt die
                Person im Unklaren, ob die Mail versendet wurde oder nicht. Beispiele für Probleme mit dem Hören sind: `,
                listitem1: "Tinnitus",
                listitem2: "Schwerhörigkeit",
                listitem3: "Hörsturz",
                listitem4: "Vollständige Taubheit",
                imagetitle: "Ein Hörgerät wird am Ohr angebracht",
              },
              motoric: {
                h2: `Motorisch`,
                h3: `Einschränkungen der Geschicklichkeit`,
                text: `Personen mit motorischen Einschränkungen können eine PC-Maus nicht
                bedienen und sind auf die Nutzung der Tastatur angewiesen. Oder aber
                Bedienelemente wie Buttons oder Links werden nicht „getroffen“, weil
                sie zu klein dargestellt sind. Hier ein paar Beispiele für motorische Herausforderungen: `,
                listitem1:
                  "verminderte Feinmotorik wie Händezittern durch Erkrankungen",
                listitem2: "Lähmungen",
                listitem3: "Polyneuropathie",
                listitem4: "Amputationen von Hand oder Arm",
                imagetitle: "Eine Armprothese wird geprüft",
              },
              cognitive: {
                h2: `Kognitiv`,
                h3: `Einschränkungen der geistigen Verarbeitung`,
                text: `Viele von uns waren schonmal auf einer Webseite und haben die
                Textinhalte nicht verstanden, weil sie zu komplex formuliert waren.
                So geht es manchen Menschen ständig. Weiterhin können unruhige
                Bildelemente oder viele Animationen ablenken oder verwirren,
                stroboskopartige Effekte können sogar epileptische Anfälle auslösen.
                Außerdem führen unnötig eingesetzte Animationen und Effekte zu einem
                erhöhten Datenverbrauch und reduzieren damit die Nachhaltigkeit. Beispiele für kognitive Einschränkungen sind: `,
                listitem1: "Migräne",
                listitem2:
                  "Konzentrationsschwierigkeiten, leichte Ablenkbarkeit, ADHS",
                listitem3: "Leseschwäche",
                listitem4: "Epilepsie",
                imagetitle:
                  "Eine junge Frau sitzt vorm PC Bildschirm und reibt sich unkonzentriert die Stirn.",
              },
            },
            section5: {
              h2: `Barrierefreiheit nützt uns allen!`,
              p1: `Barrierefreiheit ist mehr als nur eine Anpassung an dauerhafte Einschränkungen - sie betrifft jeden, unabhängig davon, ob die Beeinträchtigung permanent oder temporär ist. Jeder Mensch kann zeitweise oder durch bestimmte Situationen bedingt eingeschränkt sein.`,
              p2: `Wir verstehen, dass Einschränkungen vielseitig sein können. Deshalb legen wir besonderen Fokus darauf, digitale Inhalte und Ressourcen so zu gestalten, dass sie für alle zugänglich sind. Ob es nun um dauerhafte Herausforderungen oder vorübergehende Beeinträchtigungen geht - unsere Lösungen sind darauf ausgerichtet, die Barrierefreiheit für jeden Nutzer zu gewährleisten.`,
              button: `Analyse anfragen`,
            },
            table: {
              situative: `Situativ`,
              temporary: `Temporär`,
              permanent: `Permanent`,
              visual: `Sehen`,
              auditive: `Hören`,
              motoric: `Motorische Kontrolle`,
              physical: `Physische Beweglichkeit`,
              cognitive: `Kognition`,
              cell1: `Grelles Licht`,
              cell2: `Grauer Star`,
              cell3: `Blind`,
              cell4: `Laute Umgebung`,
              cell5: `Lautsprecher defekt`,
              cell6: `Taub`,
              cell7: `Alkohol`,
              cell8: `Display kaputt`,
              cell9: `Zittern`,
              cell10: `Baby im Arm`,
              cell11: `Handbruch`,
              cell12: `Amputation`,
              cell13: `Stress`,
              cell14a: `Un`,
              cell14b: `konzentriert`,
              cell15a: `Nicht-Mutter`,
              cell15b: `sprachler`,
            },
            section6: {
              p: `Barrierefreiheit ist nicht nur eine technische Anforderung, sondern vor allem eine Möglichkeit, digitale Räume für alle zugänglich zu machen. Durch die Integration solcher Prinzipien wird das Internet zu einem Ort, der die Vielfalt und Einzigartigkeit jedes Nutzers respektiert und fördert.`,
              imagealt: `Eine Person nutzt ein Braille Board für Blindenschrift`,
            },
            section7: {
              h2: `Richtlinien für barrierefreie Webauftritte`,
              p: `Praktische Handlungsanweisungen finden sich in den Leitlinien für barrierefreie Webinhalte (WCAG = Web Content Accessibility Guidelines) 2.1. Diese Richtlinien wurden erstmals im Jahr 1999 vom World Wide Web Consortium (W3C) veröffentlicht und seither aktualisiert. Sie legen fest, dass eine Website vier grundlegende Prinzipien erfüllen sollte:`,
              item1: `Wahrnehmbar`,
              item2: `Bedienbar`,
              item3: `Verständlich`,
              item4: `Robust`,
            },
            section7b: {
              p: `Aktuell umfassen die Web Content Accessibility Guidelines (WCAG) 2.1 insgesamt 78 Erfolgskriterien, die darauf abzielen, Barrieren abzubauen. Diese Kriterien sind in drei Stufen unterteilt. Die WCAG-Konformität einer Webseite wird anhand der Erfüllung bestimmter Kriterien und Stufen bewertet, wodurch eine differenzierte Einschätzung ihrer Zugänglichkeit ermöglicht wird.`,
              A: `Stufe A: Mindestanforderungen`,
              AA: `Stufe AA: gesetzlich geforderte Anforderungen mittlerer Höhe`,
              AAA: `Stufe AAA: Höchstanforderungen mit erweiterten Kriterien wie Gebärdensprache`,
            },
            section8: {
              h2: `Wer ist zum Einrichten eines barrierefreien Auftritts verpflichtet?`,
              p: `Seit 2019 sind öffentliche Einrichtungen, darunter Behörden, kommunale Verwaltungen, Regierungsorgane, Polizei und Bildungseinrichtungen, gesetzlich verpflichtet, ihre Webpräsenzen barrierefrei zu gestalten. Diese Verpflichtung wird durch die EU-Richtlinie 2016/2102 sowie das Behindertengleichstellungsgesetz vom 27. April 2002 festgelegt. Ab Juni 2025 sind auch private Unternehmen im Online-Handel dazu verpflichtet, barrierefreien Zugang zu gewährleisten. Es gibt jedoch Ausnahmen, wie beispielsweise für Kleinstunternehmen mit weniger als 10 Mitarbeitern und einem Jahresumsatz von unter 2 Millionen Euro. Diese Regelungen werden durch das Barrierefreiheitsstärkungsgesetz (BFSG) und das Gesetz zur Umsetzung der Richtlinie (EU) 2019/882 festgelegt.`,
              button: `überprüfen lassen`,
            },
            section8b: {
              h3a: `Welche Konformitätsstufe muss der Webauftritt meines Unternehmens erreichen?`,
              pa: `Nach der Norm EN 301 549 sind Webauftritte zur Erfüllung der AA-Kriterien verpflichtet.`,
              h3b: `Welche Vorteile habe ich, wenn ich meinen Webauftritt barrierefrei gestalte?`,
              item1: `Erhöhte Reichweite durch größeren potentiellen Kundenkreis`,
              item2: `Suchmaschinenoptimiert (SEO)`,
              item3: `Vorbildfunktion`,
              item4: `Verbesserte Zugänglichkeit und Nutzerfreundlichkeit`,
              item5: `Erfüllung hoher technischer Standards`,
            },
          },
          navbar: {
            n1: `Startseite`,
            n2: "Barrierefreiheit",
            n3: "Nachhaltigkeit",
            n4: `Kontakt`,
          },
          burgermenu: {
            open: `Menü öffnen und schliessen`,
          },
          kontakt: {
            section1: {
              h1: `Kontaktieren Sie uns!`,
              h2: `Wir freuen uns, Sie in Ihrem Projekt zu unterstützen.`,
              h3: `Wir freuen uns, Sie in Ihrem Projekt zu unterstützen. Schreiben Sie uns einfach eine Nachricht und wir helfen Ihnen gern weiter.`,
              p: `Wir bringen Ihr Projekt voran: Unabhängig davon, ob Sie
              bereits einen Webauftritt haben oder nicht - ein
              Barrierefreiheits-Check einer Webseite ist genauso möglich wie deren
              Überarbeitung. Wir geben Ihnen konkrete Tipps, wie Sie Ihre Webseite barrierefrei gestalten und helfen bei der Umsetzung. Auch eine Neuentwicklung von Grund auf ist
              möglich - sprechen Sie uns einfach an!`,
              imagealt: `Michael Gottschalk und Verena Schwachmeyer`,
            },
            section2: {
              h2: "Wie können wir Ihnen helfen?",
              p: `Je genauer wir verstehen, was Sie brauchen, desto besser können wir Ihren Wünschen entsprechen.`,
              label: `Bitte teilen Sie uns Ihr Anliegen mit:`,
              formoption1: `Neuentwicklung`,
              formoption2: `Prüfung eines existierenden Webauftritts `,
              formoption3: `Re-Design`,
              formoption4: `Allgemeine Anfrage`,
              formoption5: `Sonstiges`,
            },
            form: {
              h3: `Ihre Nachricht:`,
              name: `Name`,
              mail: `E-Mail Addresse`,
              message: `Nachricht`,
              button: `Senden`,
              sending: "Mail wird gesendet...",
            },
          },
          modal: {
            header: "Vielen Dank für Ihre Nachricht",
            text: "Ihre Nachricht wurde erfolgreich gesendet. Wir werden uns zeitnah bei Ihnen melden.",
            error:
              "Hups! Etwas ist schiefgelaufen - sorry! Bitte versuchen Sie es später nochmal.",
          },
        },
      },
    },
  });

export default i18n;
