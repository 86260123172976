import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/burgermenu.css";

export default function BurgerMenu() {
  const [burgermenuOpen, setBurgermenuOpen] = useState(false);
  const { t } = useTranslation();

  const burgerMenuItems = [
    { title: t("navigation.home"), path: "/" },
    { title: t("navigation.acc"), path: "/about" },
    { title: t("navigation.sus"), path: "/contact" },
    { title: t("navigation.cont"), path: "/addplants" },
  ];

  // const toggleMenu = () => {
  //   setBurgermenuOpen(!burgermenuOpen);
  // };

  return (
    <div className="Burgermenu-Container">
      <div></div>
      <div></div>
      <div></div>
      {/* <div
        className={
          burgermenuOpen
            ? "burgermenu-bars-container openedmenu"
            : "burgermenu-bars-container"
        }
      >
        <button
          className="burgermenu-button"
          aria-label={t("burgermenu.open")}
          onClick={() => toggleMenu()}
        ></button>
        <div
          className={
            burgermenuOpen ? "burgermenu-bars cross-1" : "burgermenu-bars"
          }
          style={{ top: "15%" }}
        ></div>
        <div
          className={
            burgermenuOpen ? "burgermenu-bars cross-2" : "burgermenu-bars"
          }
          style={{ top: "30%" }}
        ></div>
        <div
          className={
            burgermenuOpen ? "burgermenu-bars cross-3" : "burgermenu-bars"
          }
          style={{ top: "45%" }}
        ></div>
        {/* {burgermenuOpen ? (
          <div className="instr openedmenu" style={{ top: "55%" }}>
            Schliessen
          </div>
        ) : (
          <div className="instr">öffnen</div>
        )} 
      </div> */}

      {burgermenuOpen && (
        <div className="overlay">
          <menu className={burgermenuOpen ? "burgermenu opened" : "burgermenu"}>
            {burgerMenuItems &&
              burgerMenuItems.map((burgerItem, index) => {
                return (
                  <li className="burgermenu-listItems" key={index}>
                    <a href={burgerItem.path}>{burgerItem.title}</a>
                  </li>
                );
              })}
          </menu>
        </div>
      )}
    </div>
  );
}
