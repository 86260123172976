import React from "react";
import { useTranslation } from "react-i18next";
import { useState, useContext } from "react";
import "../styles/switchbar.css";
// import { toggleFontSize } from "../utils/functions";
import { ColorThemeContext, FontSizeContext, NavbarSideContext } from "../App";
import { IoHandRightOutline, IoHandLeftOutline } from "react-icons/io5";
import { MdOutlineLightMode, MdOutlineNightlight } from "react-icons/md";
import SwitchMenu from "./SwitchMenu";

const lngs = {
  de: { nativeName: "DE", description: "deutsch" },
  en: { nativeName: "EN", description: "english" },
};

export default function SwitchBar() {
  const [language, setLanguage] = useState("DE");
  const [switchOpen, setSwitchOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const [fontSize, setFontSize] = useContext(FontSizeContext);
  const [colorTheme, setColorTheme] = useContext(ColorThemeContext);
  const [side, setSide] = useContext(NavbarSideContext);

  return (
    <>
      <div className={switchOpen ? "switchbar open" : "switchbar"}>
        <div className="switchbarItem" id="switch-Left-Right">
          <button
            className={
              side === "left" ? "switchbutton selected" : "switchbutton"
            }
            name="left"
            onClick={() => setSide("left")}
            aria-label={t("switchbar.left")}
          >
            <IoHandLeftOutline className="switchbuttonIcon" />
          </button>
          <button
            className={
              side === "right" ? "switchbutton selected" : "switchbutton"
            }
            name="right"
            onClick={() => setSide("right")}
            aria-label={t("switchbar.right")}
          >
            <IoHandRightOutline className="switchbuttonIcon" />
          </button>
        </div>
        <div className="switchbarItem">
          <button
            className={
              fontSize === "12px" ? "switchbutton selected" : "switchbutton"
            }
            name="small"
            style={{ fontSize: "0.7em", padding: 0, margin: 0 }}
            onClick={() => setFontSize("12px")}
            aria-label={t("switchbar.small")}
          >
            A
          </button>
          <button
            className={
              fontSize === "16px" ? "switchbutton selected" : "switchbutton"
            }
            name="medium"
            style={{
              fontSize: "clamp(0.6875rem, 0.5804rem + 0.5714vw, 0.9375rem)",
            }}
            onClick={() => setFontSize("16px")}
            aria-label={t("switchbar.med")}
          >
            A
          </button>
          <button
            className={
              fontSize === "20px" ? "switchbutton selected" : "switchbutton"
            }
            name="large"
            style={{
              fontSize: "clamp(0.875rem, 0.7679rem + 0.5714vw, 1.125rem)",
            }}
            onClick={() => setFontSize("20px")}
            aria-label={t("switchbar.large")}
          >
            A
          </button>
        </div>
        <div className="switchbarItem">
          <button
            className={
              colorTheme === "light" ? "switchbutton selected" : "switchbutton"
            }
            name="light"
            onClick={() => setColorTheme("light")}
            aria-label={t("switchbar.arialight")}
          >
            <MdOutlineLightMode className="switchbuttonIcon" />
          </button>
          <button
            className={
              colorTheme === "dark" ? "switchbutton selected" : "switchbutton"
            }
            name="dark"
            onClick={() => setColorTheme("dark")}
            aria-label={t("switchbar.ariadark")}
          >
            <MdOutlineNightlight className="switchbuttonIcon" />
          </button>
        </div>
        <div className="switchbarItem">
          {Object.keys(lngs).map((lng) => (
            <button
              className={
                language === lngs[lng].nativeName
                  ? "switchbutton selected"
                  : "switchbutton"
              }
              key={lng}
              style={{
                margin: "1%",
              }}
              type="submit"
              name={lngs[lng].nativeName}
              aria-label={`Sprache: ${lngs[lng].description}`}
              onClick={(e) => {
                i18n.changeLanguage(lng);
                setLanguage(e.target.name);
              }}
            >
              {lngs[lng].nativeName}
            </button>
          ))}
        </div>
        <div className="switchbarItem"></div>
        <SwitchMenu switchOpen={switchOpen} setSwitchOpen={setSwitchOpen} />
      </div>
    </>
  );
}
