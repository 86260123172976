import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BarLoader from "react-spinners/BarLoader";
import ContactField from "./ContactField";
import "../styles/person.css";
import "../styles/loading.css";

export default function Person() {
  const { t } = useTranslation();
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [formData1, setFormData1] = useState({
    id: "Verena",
    name: "",
    email: "",
    message: "",
  });
  const [formData2, setFormData2] = useState({
    id: "Micha",
    name: "",
    email: "",
    message: "",
  });

  const persondata = [
    {
      id: 1,
      text1: t("startseite.sectionVerena.intro"),
      name: "Verena Schwachmeyer",
      keywords: t("startseite.sectionVerena.function"),
      descr: t("startseite.sectionVerena.description"),
      path2li: "https://www.linkedin.com/in/verena-schwachmeyer/",
      path2photo: "/images/Verena-Schwachmeyer-bw-1000x600.jpg",
    },
    {
      id: 2,
      text1: t("startseite.sectionMicha.intro"),
      name: "Michael Gottschalk",
      keywords: t("startseite.sectionMicha.function"),
      descr: t("startseite.sectionMicha.description"),
      path2li: "https://www.linkedin.com/in/michael-gottschalk-1b4565169/",
      path2photo: "/images/Michael-Gottschalk-bw-1000x667.jpg",
    },
  ];

  const override = {
    margin: "auto",
  };

  return (
    <>
      {persondata.map((element) => {
        const funcname = element.id === 1 ? setFormData1 : setFormData2;
        const statename = element.id === 1 ? formData1 : formData2;
        const setLoading = element.id === 1 ? setLoading1 : setLoading2;
        const loading = element.id === 1 ? loading1 : loading2;

        return (
          <div
            key={element.id}
            className="textBoxContainer"
            style={
              element.id === 2
                ? { flexDirection: "row-reverse", justifyContent: "flex-end" }
                : null
            }
          >
            <div
              className="textBox yellowBox"
              style={{ justifyContent: "space-evenly" }}
            >
              {/* <p>{element.text1}</p> */}
              <div>
                <h2>{element.name}</h2>
                <h3>{element.keywords}</h3>
              </div>
              <ContactField
                funcname={funcname}
                statename={statename}
                setLoading={setLoading}
                loading={loading}
              />
              {loading && (
                <div className="loadingContainer">
                  <BarLoader
                    className="loadingSpinner"
                    loading={loading}
                    cssOverride={override}
                    size={100}
                    aria-busy="true"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <p>Mail wird gesendet...</p>
                </div>
              )}

              <Link
                title={t("startseite.linkedin.aria")}
                className="linkedinLink"
                to={element.path2li}
                rel="noreferrer"
                target="_blank"
                aria-label={t("startseite.linkedin.aria")}
              >
                <img
                  className="logo-black"
                  src="/images/linkedin_logo_icon_white.svg"
                  height="30px"
                  alt="Linkedin logo"
                ></img>
              </Link>
            </div>
            <div className="textBox yellowBox">
              <div style={{ width: "100%", position: "relative" }}>
                <img
                  className="portrait"
                  src={element.path2photo}
                  height="60%"
                  width="100%"
                  alt={`Portrait ${element.name}`}
                ></img>
                <div
                  className={element.id === 2 ? "layer right" : "layer left"}
                  style={{ width: "33%" }}
                ></div>
              </div>
              <p>{element.descr}</p>
            </div>
          </div>
        );
      })}
    </>
  );
}
