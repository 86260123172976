import React, { useState, createContext } from "react";
import { Routes, Route } from "react-router-dom";
import "../src/styles/App.css";

// import { useTranslation } from "react-i18next";
import Home from "./pages/Home";
import NotFound404 from "./pages/NotFound404";
import Datenschutz from "./pages/Datenschutz";
import Impressum from "./pages/Impressum";
import Accessibility from "./pages/Accessibility";
import Contact from "./pages/Contact";
import SkipLink from "../src/components/SkipLink";
import Scroll2Top from "../src/components/Scroll2Top";
import Header from "./components/Header";
import Footer from "./components/Footer";

export const FontSizeContext = createContext();
export const ColorThemeContext = createContext();
export const NavbarSideContext = createContext();

export default function App() {
  // const { t, i18n } = useTranslation();

  const [fontSize, setFontSize] = useState("16px");
  const [colorTheme, setColorTheme] = useState("light");
  const [side, setSide] = useState("right");

  return (
    <ColorThemeContext.Provider value={[colorTheme, setColorTheme]}>
      <FontSizeContext.Provider value={[fontSize, setFontSize]}>
        <NavbarSideContext.Provider value={[side, setSide]}>
          <div
            className="App"
            data-theme={colorTheme}
            style={{ fontSize: fontSize }}
          >
            <Scroll2Top />
            <SkipLink />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/barrierefreiheit" element={<Accessibility />} />
              {/* <Route path="/nachhaltigkeit" element={<Sustainability />} /> */}
              <Route path="/kontakt" element={<Contact />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/datenschutz" element={<Datenschutz />} />
              <Route path="/*" element={<NotFound404 />} />
            </Routes>
            <Footer />
          </div>
        </NavbarSideContext.Provider>
      </FontSizeContext.Provider>
    </ColorThemeContext.Provider>
  );
}
