import React from "react";
import BurgerMenu from "./BurgerMenu";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { ColorThemeContext, NavbarSideContext } from "../App";
import "../styles/navbar.css";

export default function Navbar() {
  const { t } = useTranslation();

  const items = [
    { title: t("navigation.home"), path: "/" },
    {
      title: t("navigation.acc"),
      path: "/barrierefreiheit",
    },
    // {
    //   t("navigation.sus"),
    //   path: "/nachhaltigkeit",
    // },
    { title: t("navigation.cont"), path: "/kontakt" },
  ];
  const burgermenu = true;

  const [colorTheme] = useContext(ColorThemeContext);
  const [side] = useContext(NavbarSideContext);

  return (
    <>
      <nav className={side === "left" ? "left" : ""}>
        {/* // import logo here */}
        <div className="navLogo-container">
          <img
            className="navLogo"
            src={
              colorTheme === "light"
                ? "/images/logo_bildmarke_black.svg"
                : "/images/logo_bildmarke_white.svg"
            }
            alt="Schwachmeyer Gottschalk Logo"
          ></img>
        </div>
        <div className="navBar-Container">
          <div className="navItem-Container">
            {items &&
              items.map((element, index) => {
                return (
                  <NavLink className="navItem" key={index} to={element.path}>
                    {element.title}
                  </NavLink>
                );
              })}
          </div>
          {burgermenu && <BurgerMenu />}
        </div>
      </nav>
    </>
  );
}
