import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function NotFound404() {
  return (
    <>
      <Header />
      <section>
        <h1>Hoppla! 404 Error</h1>
        <p>Hier hätten Sie eigentlich nicht landen dürfen</p>
      </section>
      <Footer />
    </>
  );
}
