import React, { useState } from "react";
import { sendmail } from "../utils/functions";
import { useTranslation } from "react-i18next";
import ModalSendMail from "../components/ModalSendMail";
import "../styles/person.css";

export default function ContactField({
  funcname,
  statename,
  setLoading,
  loading,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const url = process.env.REACT_APP_MAIN_BACKEND_DEPLOYMENT;

  const handleChange = (event, func, statename) => {
    const { name, value } = event.currentTarget;
    func({
      ...statename,
      [name]: value,
    });
  };

  return (
    <>
      <form
        onSubmit={(e) =>
          sendmail(e, funcname, statename, url, setLoading, t, setOpen)
        }
      >
        <input
          type="text"
          name={`name`}
          placeholder={t("kontakt.form.name")}
          value={statename.name}
          onChange={(e) => handleChange(e, funcname, statename)}
        ></input>
        <input
          type="email"
          name="email"
          value={statename.email}
          placeholder={t("kontakt.form.mail")}
          onChange={(e) => handleChange(e, funcname, statename)}
        ></input>
        <textarea
          name={`message`}
          placeholder={t("kontakt.form.message")}
          value={statename.message}
          onChange={(e) => handleChange(e, funcname, statename)}
        ></textarea>
        <button>{t("kontakt.form.button")}</button>
      </form>
      {open && <ModalSendMail open={open} setOpen={setOpen} />}
    </>
  );
}
