import React from "react";
import { useTranslation } from "react-i18next";
import Person from "../components/Person";
import { Scroll2Element } from "../utils/functions";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export default function Home() {
  const { t } = useTranslation();

  const logoData = [
    {
      id: 1,
      tooltipId: "JStooltip",
      tooltipContent: "JavaScript",
      imageSrc: "/images/logos_deck_stack/js_logo_icon_black.svg",
      altText: "logo JavaScript",
      ariaLabel: "JavaScript",
    },
    {
      id: 2,
      tooltipId: "node-tooltip",
      tooltipContent: "Node JS",
      imageSrc: "/images/logos_deck_stack/node_icon_black.svg",
      altText: "logo NodeJS",
      ariaLabel: "NodeJS",
    },
    {
      id: 3,
      tooltipId: "react-tooltip",
      tooltipContent: "React JS",
      imageSrc: "/images/logos_deck_stack/reactjs_logo_icon_black.svg",
      altText: "logo reactJS",
      ariaLabel: "ReactJS",
    },
    {
      id: 4,
      tooltipId: "html-tooltip",
      tooltipContent: "HTML5",
      imageSrc: "/images/logos_deck_stack/html5_logo_icon_black.svg",
      altText: "logo HTML5",
      ariaLabel: "HTML5",
    },
    {
      id: 5,
      tooltipId: "css-tooltip",
      tooltipContent: "CSS 3",
      imageSrc: "/images/logos_deck_stack/css3_logo_icon_black.svg",
      altText: "logo CSS 3",
      ariaLabel: "CSS3",
    },
    {
      id: 6,
      tooltipId: "sql-tooltip",
      tooltipContent: "MySQL",
      imageSrc: "/images/logos_deck_stack/mysql_logo_black.svg",
      altText: "logo MySQL",
      ariaLabel: "MySQL",
    },
  ];

  const serviceList = [
    { id: 1, service: t("startseite.section3b.item1") },
    { id: 2, service: t("startseite.section3b.item2") },
    { id: 3, service: t("startseite.section3b.item3") },
    { id: 4, service: t("startseite.section3b.item4") },
  ];

  return (
    <>
      <main id="main">
        {/* */}
        <section tabIndex={0}>
          <div
            id="h1Box"
            className="textBox yellowBox"
            style={{ width: "60%", height: "100%" }}
          >
            <div>
              <h1>{t("startseite.section1.h1")}</h1>
              <h2>{t("startseite.section1.h2")}</h2>
            </div>

            <button
              className="buttonlink"
              onClick={(e) => Scroll2Element(e, `#contact`)}
            >
              {t("startseite.section1.button")}
            </button>
          </div>
          <div
            className="background left"
            title={t("startseite.section1.imagealt")}
            style={{
              backgroundImage:
                "url(/images/section_00_Maya-Hässig-für-Ohrenkuss.jpg)",
            }}
          >
            <div className="layer left"></div>
          </div>
        </section>
        <section tabIndex={0}>
          <div className="textBox">
            <h2>{t("startseite.section2.stackdeck")}</h2>
            <ul className="logoList">
              {logoData.map((logoItem) => (
                <li key={logoItem.id} tabIndex={0}>
                  <Tooltip id={logoItem.tooltipId} className="tooltips" />
                  <img
                    className="logosStackDeck"
                    data-tooltip-id={logoItem.tooltipId}
                    data-tooltip-content={logoItem.tooltipContent}
                    src={logoItem.imageSrc}
                    alt={logoItem.altText}
                    aria-label={logoItem.ariaLabel}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="textBox yellowBox">
            <h2>{t("startseite.section2.h2")}</h2>
            <h3>{t("startseite.section2.h3")}</h3>
            <p>{t("startseite.section2.p")}</p>
            <a
              className="button buttonlink"
              href="/barrierefreiheit"
              aria-label={t("startseite.section2.ariabutton")}
            >
              {t("startseite.section2.button")}
            </a>
          </div>
        </section>
        <section tabIndex={0}>
          <div className="textBox yellowBox">
            <h2>{t("startseite.section3.h2")}</h2>

            <p>{t("startseite.section3.p")}</p>
            {/* <button aria-label={t("startseite.section3.ariabutton")}>
            {t("startseite.section3.button")}
          </button> */}
          </div>
          <div className="textBox">
            <h2>{t("startseite.section3b.h2")}</h2>
            <ul>
              {serviceList &&
                serviceList.map((item) => {
                  return (
                    <li key={item.id} style={{ margin: "2% 0px" }}>
                      <span>
                        {" "}
                        <img
                          src="/images/arrow_short.svg"
                          alt="Pfeil"
                          height="10px"
                          style={{ margin: "10px" }}
                        ></img>
                        {item.service}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </section>
        <section id="contact" tabIndex={0}>
          <Person />
        </section>
      </main>
    </>
  );
}
