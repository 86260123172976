import React from "react";
import { Trans } from "react-i18next";
import { Scroll2Element } from "../utils/functions";

export default function SkipLink() {
  return (
    <>
      <a href="#main" className="skip" tabIndex={0}>
        <Trans i18nKey="skiplink.text"></Trans>
      </a>
    </>
  );
}
