import React from "react";
import { Modal } from "react-responsive-modal";
import { useTranslation } from "react-i18next";

export default function ModalSendMail({ open, setOpen }) {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        closeOnEsc="true"
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
          overlayAnimationIn: "customEnterOverlayAnimation",
          overlayAnimationOut: "customLeaveOverlayAnimation",
        }}
        animationDuration={800}
        aria-modal="true"
        aria-labelledby="Message-was-sent"
        aria-describedby="modal-description"
        aria-busy="false"
      >
        <h3 id="Message-was-sent">{t("modal.header")}</h3>
        <p id="modal-description">{t("modal.text")}</p>
      </Modal>
    </div>
  );
}
