import React from "react";
import { useTranslation } from "react-i18next";
import "../styles/table.css";

export default function Table() {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="textBox"
        role="table"
        tabIndex={0}
        style={{ width: "60%" }}
      >
        <caption className="hidden">
          Beispiele für Einschränkungen im Alltag
        </caption>
        <div role="rowgroup" className="grid-Container grid-Header">
          <div role="columnheader" className="cell col1"></div>
          <div role="columnheader" className="cell colHeader">
            {t("barrierefreiheit.table.situative")}
          </div>
          <div role="columnheader" className="cell colHeader">
            {t("barrierefreiheit.table.temporary")}
          </div>
          <div role="columnheader" className="cell colHeader">
            {t("barrierefreiheit.table.permanent")}
          </div>
        </div>
        <div role="row" className="grid-Container">
          <div role="rowheader" className="cell col1">
            {t("barrierefreiheit.table.visual")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_sonne.svg"
              alt="Sonne"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell1")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_auge.svg"
              alt="Auge mit grauem Star"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell2")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_blind.svg"
              alt="Blindensymbol"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell3")}
          </div>
        </div>
        <div role="row" className="grid-Container">
          <div role="rowheader" className="cell col1">
            {t("barrierefreiheit.table.auditive")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_tram.svg"
              alt="Zug"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell4")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_lautsprecher.svg"
              alt="Lautsprecher"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell5")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_taub.svg"
              alt="Ohr"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell6")}
          </div>
        </div>
        <div role="row" className="grid-Container">
          <div role="rowheader" className="cell col1">
            {t("barrierefreiheit.table.motoric")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_bier.svg"
              alt="Bier"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell7")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_smartphone.svg"
              alt="Smartphone"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell8")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_zitternde_hand.svg"
              alt="zitternde Hand"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell9")}
          </div>
        </div>
        <div role="row" className="grid-Container">
          <div role="rowheader" className="cell col1">
            {t("barrierefreiheit.table.physical")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_frau_kind.svg"
              alt="Frau mit Baby"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell10")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_verletzte_Hand.svg"
              alt="Hand mit Bruch"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell11")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_arm_fehlt.svg"
              alt="Person mit nur einem Arm"
              className="tableIcons"
              style={{ height: "50px" }}
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell12")}
          </div>
        </div>
        <div role="row" className="grid-Container">
          <div role="rowheader" className="cell col1">
            {t("barrierefreiheit.table.cognitive")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_stress.svg"
              alt="Stress"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell13")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_gehirn.svg"
              alt="Gehirn"
              className="tableIcons"
              aria-hidden={true}
            ></img>
            {t("barrierefreiheit.table.cell14a")}
            <wbr></wbr>
            {t("barrierefreiheit.table.cell14b")}
          </div>
          <div role="cell" className="cell col">
            <img
              src="/images/icon_sprache.svg"
              alt="Sprechblasen"
              className="tableIcons"
              aria-hidden={true}
            ></img>

            {t("barrierefreiheit.table.cell15a")}
            <wbr></wbr>
            {t("barrierefreiheit.table.cell15b")}
          </div>
        </div>
        <p id="source">Quelle: www.webdesign-journal.de</p>
      </div>
    </>
  );
}
