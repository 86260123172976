import axios from "axios"; // Import axios at the beginning of the file

export const handleChange = (event, func, statename) => {
  const { name, value } = event.currentTarget;
  func({
    ...statename,
    [name]: value,
  });
};

export async function sendmail(
  event,
  func,
  statename,
  url,
  setLoading,
  t,
  setOpen
) {
  event.preventDefault();
  setLoading(true);

  await axios
    .post(`${url}`, statename)
    .then(function (response) {
      // console.log(response.data);
      if (response.data === "success") {
        setLoading(false);
        setOpen(true);
        func({ id: statename.id, aim: "", name: "", email: "", message: "" });
      }
    })
    .catch(function (error) {
      alert(t("modal.error"));
    });
  setLoading(false);
}

export const Scroll2Element = (e, selector) => {
  e.preventDefault();
  const targetElement = document.querySelector(selector);
  targetElement.scrollIntoView({ behavior: "smooth" });
};
